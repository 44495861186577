<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme"
    :class="['layout-menu', 'menu-vertical', 'menu', 'bg-menu-theme ', { 'is-open': isSidebarOpen }]">
    <div class="app-brand demo">
      <router-link to="/home" class="app-brand-link">
        <img style="width: 100%" src="/assets/img/logo/BOW.png" alt="" />
      </router-link>
 
      <span class=" ms-auto d-block d-xl-none">
        <span @click="SideBarOpenClose" style="margin-right: -20px;"> 
        <i class="fs-3 fi fi-rr-arrow-circle-left"></i>
      </span>
      </span>
    </div>
    <hr />
    <div class="menu-inner-shadow"></div>
    <TransporterNav v-if="user.businessNature == 'Transporter'" />
    <ExporterNav v-if="user.businessNature == 'Exporter'" />
    <ImporterNav v-if="user.businessNature == 'Importer'" />
    <OceanCarrierNav v-if="user.businessNature == 'Ocean Carrier'" />
    <ChaNav v-if="user.businessNature == 'CHA'" />
    <FreightForwarderNav v-if="user.businessNature == 'Freight Forwarder'" />
  </aside>
</template>
<script>
import TransporterNav from "@/components/common/TransporterNav.vue";
import OceanCarrierNav from "@/components/common/OceanCarrierNav.vue";
import ChaNav from "@/components/common/ChaNav.vue";
import ExporterNav from "@/components/common/ExporterNav.vue";
import FreightForwarderNav from "@/components/common/FreightForwarderNav.vue";
import ImporterNav from "@/components/common/ImporterNav.vue";

import { EventBus } from '@/eventBus';

export default {
  name: "Sidebar",
  data() {
    return {
      isSidebarOpen: false,
      isMobile: window.innerWidth <= 768,

      user: {},
      // isMenuOpen: {
      //   master: false,
      //   another: false,
      // },
    };
  },
  components: {
    TransporterNav,
    OceanCarrierNav,
    ChaNav,
    ExporterNav,
    FreightForwarderNav,
    ImporterNav,
  },
  methods: {
    SideBarOpenClose() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
      if (!this.isMobile) {
        this.isSidebarOpen = true; // Keep open on larger screens
      }
    },

    // toggleMenu(menu) {
    //   this.isMenuOpen[menu] = !this.isMenuOpen[menu];
    //   this.updateMenuState(menu);
    // },
    profileCompleateORNot() {
      this.user = JSON.parse(localStorage.getItem("userData"));
      this.role = JSON.parse(localStorage.getItem("role"));
    },
    // updateMenuState(menu) {
    //   const menuSub = this.$el.querySelector(`.menu-sub.${menu}`);
    //   if (menuSub) {
    //     if (this.isMenuOpen[menu]) {
    //       menuSub.style.display = "block";
    //     } else {
    //       menuSub.style.display = "none";
    //     }
    //   }
    // },
  },
  mounted() {
    // Listen for the event triggered by Head.vue
    EventBus.on("callSideMethod", this.SideBarOpenClose);
  },
  beforeUnmount() {
    // Clean up the listener
    EventBus.off("callSideMethod", this.SideBarOpenClose);
  },
  created() {
    this.profileCompleateORNot()
  }
};
</script>




<style scoped>
/* Sidebar hidden by default on mobile */
.layout-menu {
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

/* When the sidebar is open */
.layout-menu.is-open {
  transform: translateX(0);
}

/* Always show sidebar on larger screens */
@media (min-width: 769px) {
  .layout-menu {
    transform: translateX(0);
  }
}
</style>
