<template>

  <router-view />
</template>


<script>

export default {

  data() {
    return {
      loading: false
    };
  },

};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.menu-vertical .menu-item .menu-link,
.menu-vertical .menu-block {
  padding: 0.2rem 0.5rem !important;
}
</style>
